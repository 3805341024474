import type {TrackingEvent} from '@telefonica/mistica';

let currentPageName: string;

type GAFunction = (...args: any[]) => void;

interface WindowWithGA extends Window {
    ga: {
        q: any[];
        l: number;
    } & GAFunction;
}

declare const window: WindowWithGA;

export const initGa = (trackingId: string): void => {
    const scriptId = 'ga';

    if (document.getElementById(scriptId)) return;

    window.ga =
        window.ga ||
        function (...args): void {
            (window.ga.q = window.ga.q || []).push(args);
        };
    window.ga.l = Date.now();
    window.ga('create', trackingId, 'auto');

    const {head} = document;
    const script = document.createElement('script');
    script.id = scriptId;
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://www.google-analytics.com/analytics.js';
    if (head) {
        head.insertBefore(script, head.lastChild);
    }
};

export const gaTrackEvent = ({category, action, label, value}: TrackingEvent): void => {
    window.ga('send', 'event', {
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
    });
};

export const gaSetScreenName = (screenName: string): void => {
    if (screenName !== currentPageName) {
        currentPageName = screenName;
        window.ga('set', 'page', screenName);
        window.ga('send', 'pageView', screenName);
    }
};

export default {initGa, gaTrackEvent};
