import {MOVISTAR_ES, O2_UK, O2_ES, VIVO_BR} from './utils/brands';

const translations = {
    'Gestionar usuarios': {
        [MOVISTAR_ES]: 'Gestionar usuarios',
        [O2_ES]: 'Gestionar usuarios',
        [O2_UK]: 'Manage users',
        [VIVO_BR]: 'Gerenciar contas',
    },
    'Acceso a 1$s': {
        [MOVISTAR_ES]: 'Acceso a 1$s',
        [O2_ES]: 'Acceso a 1$s',
        [O2_UK]: 'Access to 1$s',
        [VIVO_BR]: 'Acesso ao Login 1$s',
    },
    'Puedes volver a intentarlo': {
        [MOVISTAR_ES]: 'Puedes volver a intentarlo',
        [O2_ES]: 'Puedes volver a intentarlo',
        [O2_UK]: 'You can try again',
        [VIVO_BR]: 'Você pode tentar novamente',
    },
    'Algo ha ido mal': {
        [MOVISTAR_ES]: 'Algo ha ido mal',
        [O2_ES]: 'Algo ha ido mal',
        [O2_UK]: 'Something went wrong',
        [VIVO_BR]: 'Algo deu errado',
    },
    'Ha ocurrido un error mientras iniciabas sesión. Inténtalo de nuevo más tarde.': {
        [MOVISTAR_ES]: 'Ha ocurrido un error mientras iniciabas sesión. Inténtalo de nuevo más tarde.',
        [O2_ES]: 'Ha ocurrido un error mientras iniciabas sesión. Inténtalo de nuevo más tarde.',
        [O2_UK]: 'An error occurred while you were logging in. Please try again later.',
        [VIVO_BR]: 'Algo deu errado ao fazer seu login. Você pode tentar fazer isso de novo mais tarde.',
    },
    Cancelar: {
        [MOVISTAR_ES]: 'Cancelar',
        [O2_ES]: 'Cancelar',
        [O2_UK]: 'Cancel',
        [VIVO_BR]: 'Cancelar',
    },
    Reintentar: {
        [MOVISTAR_ES]: 'Reintentar',
        [O2_ES]: 'Reintentar',
        [O2_UK]: 'Retry',
        [VIVO_BR]: 'Ok, voltar',
    },
    'No se ha podido eliminar el usuario 1$s': {
        [MOVISTAR_ES]: 'El usuario 1$s no se ha eliminado',
        [O2_ES]: 'El usuario 1$s no se ha eliminado',
        [O2_UK]: 'The user 1$s has not been deleted',
        [VIVO_BR]: 'Não conseguimos apagar a conta 1$s',
    },
    'Tendrás que volver a iniciar sesión con 1$s la proxima vez que uses una app de 2$s': {
        [MOVISTAR_ES]: 'Tendrás que volver a iniciar sesión con 1$s la proxima vez que uses una app de 2$s',
        [O2_ES]: 'Tendrás que volver a iniciar sesión con 1$s la proxima vez que uses una app de 2$s',
        [O2_UK]:
            'If you delete this user 1$s, we will not remember the log in details for 2$s apps in the future',
        [VIVO_BR]:
            'Ao apagar a conta 1$s, nós vamos esquecer seus dados e você não vai mais poder usá-los no Login 2$s',
    },
    '¿Seguro que quieres eliminar este usuario?': {
        [MOVISTAR_ES]: '¿Seguro que quieres eliminar este usuario?',
        [O2_ES]: '¿Seguro que quieres eliminar este usuario?',
        [O2_UK]: 'Do you want to delete the user?',
        [VIVO_BR]: 'Quer mesmo apagar a conta?',
    },
    Eliminar: {
        [MOVISTAR_ES]: 'Eliminar',
        [O2_ES]: 'Eliminar',
        [O2_UK]: 'Delete',
        [VIVO_BR]: 'Apagar',
    },
    'Has eliminado todos los usuarios disponibles.': {
        [MOVISTAR_ES]: 'Has eliminado todos los usuarios disponibles.',
        [O2_ES]: 'Has eliminado todos los usuarios disponibles.',
        [VIVO_BR]: 'Você eliminou todos os usuários disponíveis.',
    },
    'Vuelve a iniciar sesión para acceder a 1$s': {
        [MOVISTAR_ES]: 'Vuelve a iniciar sesión para acceder a 1$s',
        [O2_ES]: 'Vuelve a iniciar sesión para acceder a 1$s',
        [O2_UK]: 'Log in again to access 1$s',
        [VIVO_BR]: 'Por favor, inicie uma nova sessão pra acessar o seu Login 1$s',
    },
    'No hay usuarios disponibles': {
        [MOVISTAR_ES]: 'Has eliminado todos los usuarios guardados',
        [O2_ES]: 'No hay usuarios disponibles',
        [O2_UK]: 'There are no available users',
        [VIVO_BR]: 'Não há contas disponíveis',
    },
    'Iniciar sesión': {
        [MOVISTAR_ES]: 'Iniciar sesión',
        [O2_ES]: 'Iniciar sesión',
        [O2_UK]: 'Log in',
        [VIVO_BR]: 'Ir pro início',
    },
    'Estos son los usuarios disponibles con los que puedes acceder a las apps de 1$s': {
        [MOVISTAR_ES]: 'Estos son los usuarios disponibles con los que puedes acceder a las apps de 1$s',
        [O2_ES]: 'Estos son los usuarios disponibles con los que puedes acceder a las apps de 1$s',
        [O2_UK]: 'These are the available users that you can use to access the 1$s apps',
        [VIVO_BR]: 'Essas são as contas que você pode usar pra acessar o Login 1$s:',
    },
    '¿No eres tú? Entrar con otro usuario': {
        [MOVISTAR_ES]: '¿No eres tú? Entra con otro usuario',
        [O2_ES]: '¿No eres tú? Entra con otro usuario',
        [O2_UK]: 'Not you? Log in with another user',
        [VIVO_BR]: 'Não é você? Use outra conta',
    },
    'Acceder con otro usuario': {
        [MOVISTAR_ES]: 'Acceder con otro usuario',
        [O2_ES]: 'Acceder con otro usuario',
        [O2_UK]: 'Log in with another user', // @todo translated by copilot
        [VIVO_BR]: 'Usar outra conta',
    },
    'Por seguridad, vuelve a introducir la contraseña para iniciar sesión': {
        [MOVISTAR_ES]: 'Por seguridad, vuelve a introducir la contraseña para iniciar sesión',
        [O2_ES]: 'Por seguridad, vuelve a introducir la contraseña para iniciar sesión',
        [O2_UK]: 'For security, please re-enter your password to log in', // @todo translated by copilot
        [VIVO_BR]: 'Pra usar essa conta, confirme seus dados de acesso',
    },
    'Accede con tu usuario a 1$s': {
        [MOVISTAR_ES]: 'Accede con tu usuario a 1$s',
        [O2_ES]: 'Accede a 1$s con tu usuario',
        [O2_UK]: 'Access 1$s with your user',
        [VIVO_BR]: 'Escolha uma conta do Login 1$s pra acessar Nome Grande de App Parceiro',
    },
    'Hola de nuevo': {
        [MOVISTAR_ES]: 'Hola de nuevo',
        [O2_ES]: 'Hola de nuevo',
        [O2_UK]: 'Hello again',
        [VIVO_BR]: 'Boas-vindas!',
    },
    'Te los recordaremos cuando inicies sesión en nuestras apps y no tendrás que volver a introducir tus claves.':
        {
            [MOVISTAR_ES]:
                'Te los recordaremos cuando inicies sesión en nuestras apps y no tendrás que volver a introducir tus claves.',
            [O2_ES]:
                'Te los recordaremos cuando inicies sesión en nuestras apps y no tendrás que volver a introducir tus claves.',
            [O2_UK]: 'You will be able to quickly and simply log in to other apps.', // @todo translated by copilot
            [VIVO_BR]:
                'Dessa forma, você não vai precisar de confirmação pra iniciar sua sessão em nossos aplicativos nas próximas vezes',
        },
    'Más información': {
        [MOVISTAR_ES]: 'Más información',
        [O2_ES]: 'Más información',
        [O2_UK]: 'More information', // @todo translated by copilot
        [VIVO_BR]: 'Mais informações', // @todo translated by copilot
    },
    'Menos información': {
        [MOVISTAR_ES]: 'Menos información',
        [O2_ES]: 'Menos información',
        [O2_UK]: 'Less information', // @todo translated by copilot
        [VIVO_BR]: 'Menos informações', // @todo translated by copilot
    },
    '¿Quieres guardar estos datos de acceso para entrar en otras apps de 1$s?': {
        [MOVISTAR_ES]: '¿Quieres guardar estos datos de acceso para entrar en otras apps de 1$s?',
        [O2_ES]: '¿Quieres guardar estos datos de acceso para entrar en otras apps de 1$s?',
        [O2_UK]: 'Do you want us to remember your details?', // @todo translated by copilot
        [VIVO_BR]: 'Gostaria de salvar seus dados de acesso?',
    },
    'Sí, guardar datos': {
        [MOVISTAR_ES]: 'Sí, guardar datos',
        [O2_ES]: 'Sí, guardar datos',
        [O2_UK]: 'Yes, remember my details', // @todo translated by copilot
        [VIVO_BR]: 'Salvar dados',
    },
    'No, gracias': {
        [MOVISTAR_ES]: 'No, gracias',
        [O2_ES]: 'No, gracias',
        [O2_UK]: 'No, thanks',
        [VIVO_BR]: 'Não, obrigado',
    },
    OK: {
        [MOVISTAR_ES]: 'Ok',
        [O2_ES]: 'Ok',
        [O2_UK]: 'Ok',
        [VIVO_BR]: 'Ok',
    },
    'Eliminar usuario': {
        [MOVISTAR_ES]: 'Eliminar',
        [O2_ES]: 'Eliminar',
        [O2_UK]: 'Remove',
        [VIVO_BR]: 'Remover',
    },
    'Las cookies están inhabilitadas': {
        [MOVISTAR_ES]: 'Las cookies están inhabilitadas',
        [O2_ES]: 'Las cookies están inhabilitadas',
        [O2_UK]: 'Cookies are disabled',
        [VIVO_BR]: 'Os cookies estão desabilitados',
    },
    'Tu navegador tiene las cookies y/o javascript inhabilitado. Asegúrate de que esté todo habilitado e inténtelo de nuevo':
        {
            [MOVISTAR_ES]:
                'Tu navegador tiene las cookies y/o javascript inhabilitado. Asegúrate de que esté todo habilitado e inténtelo de nuevo',
            [O2_ES]:
                'Tu navegador tiene las cookies y/o javascript inhabilitado. Asegúrate de que esté todo habilitado e inténtelo de nuevo',
            [O2_UK]:
                'Your browser has cookies and/or javascript disabled. Make sure everything is enabled and try again',
            [VIVO_BR]:
                'Seu navegador está com cookies ou javascript desabilitados. Por favor, certifique-se de que tudo esteja funcionando e tente novamente',
        },
};

export type Token = keyof typeof translations;

export default translations;
