import type {RegionCode, Locale} from '@telefonica/mistica';

export type Brand = 'movistar-es' | 'o2-uk' | 'o2-es' | 'vivo-br';

export const MOVISTAR_ES = 'movistar-es' as const;
export const O2_UK = 'o2-uk' as const;
export const O2_ES = 'o2-es' as const;
export const VIVO_BR = 'vivo-br' as const;

export const ALL_BRANDS: Array<Brand> = [MOVISTAR_ES, O2_UK, O2_ES, VIVO_BR];

const BRAND_TO_LOCALE = {
    [MOVISTAR_ES]: 'es-ES',
    [O2_UK]: 'en-US',
    [O2_ES]: 'es-ES',
    [VIVO_BR]: 'pt-BR',
} as const;

export const createBrand = (brand: string): Brand => {
    if (ALL_BRANDS.includes(brand as Brand)) {
        return brand as Brand;
    }
    throw Error('Unexpected brand: ' + brand);
};

export const getLocaleForBrand = (brand: Brand): Locale => BRAND_TO_LOCALE[brand];

const NUMBER_FORMATTING_REGION_CODE = {
    [MOVISTAR_ES]: 'ES',
    [O2_UK]: 'GB',
    [O2_ES]: 'ES',
    [VIVO_BR]: 'BR',
} as const;

export const getPhoneNumberFormattingRegionCodeForBrand = (brand: Brand): RegionCode =>
    NUMBER_FORMATTING_REGION_CODE[brand];
