import * as React from 'react';
import {
    Box,
    Stack,
    confirm,
    BoxedRow,
    BoxedRowList,
    Divider,
    Text6,
    Text3,
    ButtonLink,
    FixedFooterLayout,
    ResponsiveLayout,
    useScreenSize,
    GridLayout,
    skinVars,
    IconAlertRegular,
} from '@telefonica/mistica';
import {getAuthParams, getAccounts, login, redirectToLogin, type UserAccount} from '../../api';
import {useDocumentTitle} from '../../hooks';
import {I18N} from '../../utils/i18n';
import {trackEvent, setScreenName} from '../../utils/analytics';
import {SSO_ACCOUNT_CHOOSER} from '../../utils/analytics/tracking/event-categories';
import {
    ACCOUNT_TAPPED,
    OTHER_USER_LINK_TAPPED,
    ACCOUNT_MGT_LINK_TAPPED,
} from '../../utils/analytics/tracking/event-actions';
import {ACCOUNT_CHOOSER_SCREEN} from '../../utils/analytics/tracking/screens';
import LoadingScreen from '../loading-screen';
import {useApplicationName, useBrand} from '../app-context-provider';

type Props = {
    navBar: React.ReactNode;
};

const AccountChooser = ({navBar}: Props): JSX.Element => {
    const appName = useApplicationName();
    const brand = useBrand();
    const {acrValues, loginHint: loginHintFromUrl, clientId = ''} = getAuthParams();
    const [loggedUsers, setLoggedUsers] = React.useState<UserAccount[]>([]);
    const [isLoading, setLoading] = React.useState(true);
    const {isDesktopOrBigger} = useScreenSize();

    useDocumentTitle(I18N.translate('Acceso a 1$s', appName));

    React.useEffect(() => {
        setScreenName(ACCOUNT_CHOOSER_SCREEN);
        getAccounts(brand, clientId, acrValues, loginHintFromUrl)
            .then((result) => {
                setLoggedUsers(result);
                if (!result || !result.length) {
                    redirectToLogin();
                } else {
                    setLoading(false);
                }
            })
            .catch(() => {
                redirectToLogin();
            });
    }, [brand, clientId, acrValues, loginHintFromUrl]);

    const onAccountSelected = (user: UserAccount): void => {
        setLoading(true);
        trackEvent({category: SSO_ACCOUNT_CHOOSER, action: ACCOUNT_TAPPED});
        login(user.ssoToken, user.loginHint)
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                confirm({
                    title: I18N.translate('Algo ha ido mal'),
                    message: I18N.translate('Puedes volver a intentarlo'),
                    acceptText: I18N.translate('Reintentar'),
                    cancelText: I18N.translate('Cancelar'),
                    onAccept: () => onAccountSelected(user),
                });
            });
    };

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <>
            {navBar}
            <FixedFooterLayout
                footer={
                    <ResponsiveLayout>
                        <GridLayout
                            template="6+6"
                            right={null}
                            left={
                                <Box paddingY={16}>
                                    <div style={{textAlign: isDesktopOrBigger ? 'initial' : 'center'}}>
                                        <Stack space={16}>
                                            <ButtonLink
                                                to={`/account-chooser/management?acr_values=${acrValues.join(
                                                    ','
                                                )}`}
                                                trackingEvent={{
                                                    category: SSO_ACCOUNT_CHOOSER,
                                                    action: ACCOUNT_MGT_LINK_TAPPED,
                                                }}
                                            >
                                                {I18N.translate('Gestionar usuarios')}
                                            </ButtonLink>

                                            {!loginHintFromUrl && (
                                                <Stack space={16}>
                                                    <Divider />
                                                    <ButtonLink
                                                        onPress={() => {
                                                            redirectToLogin();
                                                        }}
                                                        trackingEvent={{
                                                            category: SSO_ACCOUNT_CHOOSER,
                                                            action: OTHER_USER_LINK_TAPPED,
                                                        }}
                                                    >
                                                        {loggedUsers.length > 1
                                                            ? I18N.translate('Acceder con otro usuario')
                                                            : I18N.translate(
                                                                  '¿No eres tú? Entrar con otro usuario'
                                                              )}
                                                    </ButtonLink>
                                                </Stack>
                                            )}
                                        </Stack>
                                    </div>
                                </Box>
                            }
                        />
                    </ResponsiveLayout>
                }
            >
                <ResponsiveLayout>
                    <GridLayout
                        template="6+6"
                        right={null}
                        left={
                            <Box paddingTop={32} paddingBottom={24}>
                                <Stack space={24}>
                                    <Stack space={16}>
                                        <Text6 as="h1">{I18N.translate('Hola de nuevo')}</Text6>
                                        <Text3 regular color={skinVars.colors.textSecondary}>
                                            {I18N.translate('Accede con tu usuario a 1$s', appName)}
                                        </Text3>
                                    </Stack>
                                    <BoxedRowList>
                                        {loggedUsers.map((user) => (
                                            <BoxedRow
                                                asset={user.partial ? <IconAlertRegular /> : undefined}
                                                key={user.ssoToken}
                                                title={user.identifier}
                                                description={
                                                    user.partial
                                                        ? I18N.translate(
                                                              'Por seguridad, vuelve a introducir la contraseña para iniciar sesión'
                                                          )
                                                        : undefined
                                                }
                                                onPress={() => onAccountSelected(user)}
                                            />
                                        ))}
                                    </BoxedRowList>
                                </Stack>
                            </Box>
                        }
                    />
                </ResponsiveLayout>
            </FixedFooterLayout>
        </>
    );
};

export default AccountChooser;
