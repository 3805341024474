import * as React from 'react';
import {
    Box,
    Stack,
    confirm,
    IconButton,
    IconTrashCanRegular,
    Text3,
    skinVars,
    ResponsiveLayout,
    Text6,
    GridLayout,
    BoxedRowList,
    BoxedRow,
} from '@telefonica/mistica';
import {getAccounts, forget, getAuthParams} from '../../api';
import EmptyCase from './empty-case';
import {I18N} from '../../utils/i18n';
import {trackEvent, setScreenName} from '../../utils/analytics';
import {SSO_ACCOUNT_MANAGEMENT, IDENTITY_DELETE_ERROR} from '../../utils/analytics/tracking/event-categories';
import {
    DELETE_TAPPED,
    PRIMARY_BUTTON_TAPPED,
    SECONDARY_BUTTON_TAPPED,
} from '../../utils/analytics/tracking/event-actions';
import {ACCOUNT_MNGT_SCREEN} from '../../utils/analytics/tracking/screens';
import LoadingScreen from '../loading-screen';
import {useDocumentTitle} from '../../hooks';
import {useBrand, useCompanyName} from '../app-context-provider';

import type {UserAccount} from '../../api';
import type {Brand} from '../../utils/brands';

type Props = {
    navBar: React.ReactNode;
};

const AccountManagement = ({navBar}: Props): JSX.Element => {
    const [userAccounts, setUserAccounts] = React.useState<Array<UserAccount>>([]);
    const [isLoading, setLoading] = React.useState(true);
    const [showEmptyCase, setShowEmptyCase] = React.useState(false);
    const {acrValues, clientId = ''} = getAuthParams();
    const brand = useBrand();
    const companyName = useCompanyName();
    useDocumentTitle(I18N.translate('Gestionar usuarios'));

    const getUserAccounts = (brand: Brand, clientId: string, acrValues: Array<string>): void => {
        getAccounts(brand, clientId, acrValues)
            .then((result) => {
                setUserAccounts(result);
                if (!result || !result.length) {
                    setShowEmptyCase(true);
                }
            })
            .catch(() => {})
            .finally(() => setLoading(false));
    };

    React.useEffect(() => {
        setScreenName(ACCOUNT_MNGT_SCREEN);
        getUserAccounts(brand, clientId, acrValues);
    }, [brand, clientId, acrValues]);

    const onUserLogout = (ssoToken: string, identifier: string): void => {
        setLoading(true);
        forget(ssoToken)
            .then(() => {
                getUserAccounts(brand, clientId, acrValues);
            })
            .catch(() => {
                const errorAcceptTextTranslated = I18N.translate('Reintentar');
                const errorCancelTextTranslated = I18N.translate('Cancelar');
                confirm({
                    title: I18N.translate('Algo ha ido mal'),
                    message: I18N.translate('No se ha podido eliminar el usuario 1$s', identifier),
                    acceptText: errorAcceptTextTranslated,
                    cancelText: errorCancelTextTranslated,
                    onAccept: () => {
                        onUserLogout(ssoToken, identifier);
                        trackEvent({
                            category: IDENTITY_DELETE_ERROR,
                            action: PRIMARY_BUTTON_TAPPED,
                            label: errorAcceptTextTranslated,
                        });
                    },
                    onCancel: () => {
                        setLoading(false);
                        trackEvent({
                            category: IDENTITY_DELETE_ERROR,
                            action: SECONDARY_BUTTON_TAPPED,
                            label: errorCancelTextTranslated,
                        });
                    },
                });
            });
    };

    const handleRemove = (ssoToken: string, identifier: string): void => {
        const confirmAcceptTextTranslated = I18N.translate('Eliminar');
        const cancelTextTranslated = I18N.translate('Cancelar');
        confirm({
            title: I18N.translate('¿Seguro que quieres eliminar este usuario?'),
            message: I18N.translate(
                'Tendrás que volver a iniciar sesión con 1$s la proxima vez que uses una app de 2$s',
                identifier,
                companyName
            ),
            acceptText: confirmAcceptTextTranslated,
            cancelText: cancelTextTranslated,
            onAccept: () => {
                trackEvent({
                    category: SSO_ACCOUNT_MANAGEMENT,
                    action: PRIMARY_BUTTON_TAPPED,
                    label: confirmAcceptTextTranslated,
                });
                onUserLogout(ssoToken, identifier);
            },
            onCancel: () => {
                trackEvent({
                    category: SSO_ACCOUNT_MANAGEMENT,
                    action: SECONDARY_BUTTON_TAPPED,
                    label: cancelTextTranslated,
                });
            },
        });
    };

    if (isLoading) {
        return (
            <>
                {navBar}
                <LoadingScreen />
            </>
        );
    }

    if (showEmptyCase) {
        return <EmptyCase />;
    }

    return (
        <>
            {navBar}
            <ResponsiveLayout>
                <GridLayout
                    template="6+6"
                    right={null}
                    left={
                        <Box paddingTop={32} paddingBottom={24}>
                            <Stack space={24}>
                                <Stack space={16}>
                                    <Text6 as="h1">{I18N.translate('Hola de nuevo')}</Text6>
                                    <Text3 regular color={skinVars.colors.textSecondary}>
                                        {I18N.translate(
                                            'Estos son los usuarios disponibles con los que puedes acceder a las apps de 1$s',
                                            companyName
                                        )}
                                    </Text3>
                                </Stack>
                                <BoxedRowList>
                                    {userAccounts.map((user) => (
                                        <BoxedRow
                                            key={user.ssoToken}
                                            title={user.identifier}
                                            right={
                                                <IconButton
                                                    aria-label={I18N.translate('Eliminar usuario')}
                                                    onPress={() => {
                                                        trackEvent({
                                                            category: SSO_ACCOUNT_MANAGEMENT,
                                                            action: DELETE_TAPPED,
                                                        });
                                                        handleRemove(user.ssoToken, user.identifier);
                                                    }}
                                                    style={{height: '100%'}}
                                                >
                                                    <IconTrashCanRegular
                                                        size={24}
                                                        color={skinVars.colors.textLink}
                                                    />
                                                </IconButton>
                                            }
                                        />
                                    ))}
                                </BoxedRowList>
                            </Stack>
                        </Box>
                    }
                />
            </ResponsiveLayout>
        </>
    );
};

export default AccountManagement;
