import {ErrorFeedbackScreen} from '@telefonica/mistica';
import {I18N} from '../utils/i18n';

const GenericError = (): React.ReactElement => {
    return (
        <ErrorFeedbackScreen
            title={I18N.translate('Algo ha ido mal')}
            description={I18N.translate(
                'Ha ocurrido un error mientras iniciabas sesión. Inténtalo de nuevo más tarde.'
            )}
        />
    );
};

export default GenericError;
