import {initGa, gaTrackEvent, gaSetScreenName} from './google-analytics';
import {initFirebase, firebaseTrackEvent, firebaseSetScreenName} from './firebase';

import type {TrackingEvent} from '@telefonica/mistica';

let initialize: (value?: unknown) => void;

const waitInitialization = new Promise((resolve) => {
    initialize = resolve;
});

const NOVUM_GA_ID = 'UA-115771276-24';
const firebaseConfig = {
    apiKey: 'AIzaSyDHmCALG3gaJn2bzOOhS-yHCRf2ai9o40Y',
    authDomain: 'unique-login-enteprise.firebaseapp.com',
    databaseURL: 'https://unique-login-enteprise.firebaseio.com',
    projectId: 'unique-login-enteprise',
    storageBucket: 'unique-login-enteprise.appspot.com',
    messagingSenderId: '637265617757',
    appId: '1:637265617757:web:5c3bec0c550bb0ade13eb4',
    measurementId: 'G-Y24NXQTG3S',
};

export const trackEvent = (event: TrackingEvent): Promise<void> => {
    if (process.env.NODE_ENV === 'production') {
        return waitInitialization.then(() => {
            firebaseTrackEvent(event);
            gaTrackEvent(event);
        });
    }
    if (process.env.NODE_ENV !== 'test') {
        console.log('trackEvent', event);
    }
    return Promise.resolve();
};

export const setScreenName = (screenName: string): Promise<void> => {
    if (process.env.NODE_ENV === 'production') {
        return waitInitialization.then(() => {
            firebaseSetScreenName(screenName);
            gaSetScreenName(screenName);
        });
    }
    if (process.env.NODE_ENV !== 'test') {
        console.log('setScreenName', screenName);
    }
    return Promise.resolve();
};

export const initAnalytics = (): void => {
    if (process.env.NODE_ENV === 'production') {
        initFirebase(firebaseConfig);
        initGa(NOVUM_GA_ID);
    }
    initialize();
};
