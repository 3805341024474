import {initializeApp} from '@firebase/app';
import {getAnalytics, logEvent, setCurrentScreen} from '@firebase/analytics';

import type {TrackingEvent} from '@telefonica/mistica';
import type {Analytics} from '@firebase/analytics';

type FirebaseConfig = {
    measurementId: string;
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
};

let firebaseAnalytics: Analytics;

export const firebaseTrackEvent = ({category, action, label, value}: TrackingEvent): void => {
    logEvent(firebaseAnalytics, category, {
        event_category: category,
        event_action: action,
        event_label: label,
        event_value: value,
    });
};

export const firebaseSetScreenName = (screenName: string): void => {
    setCurrentScreen(firebaseAnalytics, screenName);
};

export const initFirebase = (config: FirebaseConfig): void => {
    const firebaseApp = initializeApp(config);
    firebaseAnalytics = getAnalytics(firebaseApp);
};
