import {ButtonPrimary, InfoFeedbackScreen} from '@telefonica/mistica';
import {redirectToLogin} from '../../api';
import {SSO_ERROR} from '../../utils/analytics/tracking/event-categories';
import {PRIMARY_BUTTON_TAPPED} from '../../utils/analytics/tracking/event-actions';
import {I18N} from '../../utils/i18n';
import {useCompanyName} from '../app-context-provider';
import {useDocumentTitle} from '../../hooks';

const EmptyCase = (): JSX.Element => {
    const companyName = useCompanyName();
    useDocumentTitle(I18N.translate('Acceso a 1$s', companyName));
    return (
        <InfoFeedbackScreen
            title={I18N.translate('No hay usuarios disponibles')}
            description={I18N.translate('Vuelve a iniciar sesión para acceder a 1$s', companyName)}
            primaryButton={
                <ButtonPrimary
                    trackingEvent={{
                        category: SSO_ERROR,
                        action: PRIMARY_BUTTON_TAPPED,
                        label: I18N.translate('Iniciar sesión'),
                    }}
                    onPress={() => {
                        redirectToLogin();
                    }}
                >
                    {I18N.translate('Iniciar sesión')}
                </ButtonPrimary>
            }
        />
    );
};

export default EmptyCase;
